import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import Enumerable from "linq";
import { formatRataWithoutWeekDay, formatMinutes } from "../utils/rata";
import { formatText, urlDetect } from "../utils/text";
import { Kind } from "../components/kind";
import { imageUrlPrefix } from "../utils/config";
import { Ribbon } from "../components/ribbon";
const eventStyle = require('./event.module.scss');
function pad(n, size) {
    let num = n.toString();
    while (num.length < size)
        num = "0" + num;
    return num;
}
const timeKey = (time) => {
    var _a, _b;
    return ((_a = time === null || time === void 0 ? void 0 : time.day) !== null && _a !== void 0 ? _a : 0) + '-' + pad(((_b = time === null || time === void 0 ? void 0 : time.time) !== null && _b !== void 0 ? _b : 0), 4);
};
const EventSection = ({ label, children }) => (<React.Fragment>
        <div className={eventStyle.infoLabel}>{label}:</div>
        <div>
            {children}
        </div>
    </React.Fragment>);
function normalizeLink(link) {
    return link.startsWith("http") ? link : ("http://" + link);
}
const LocationsTimes = ({ locations }) => {
    let locs = Enumerable
        .from(locations)
        .select((location, index) => {
        var _a, _b, _c;
        // @ts-ignore
        const t = (_a = location === null || location === void 0 ? void 0 : location.times) !== null && _a !== void 0 ? _a : [];
        const times = Enumerable.from(t)
            .orderBy(timeKey)
            .select((time) => {
            var _a, _b, _c;
            return (<React.Fragment key={timeKey(time)}>
                            <div className={eventStyle.timeEntry}>{formatRataWithoutWeekDay((_a = time === null || time === void 0 ? void 0 : time.day) !== null && _a !== void 0 ? _a : 0)}, {formatMinutes((_b = time === null || time === void 0 ? void 0 : time.time) !== null && _b !== void 0 ? _b : 0)}</div>
                            <div>{urlDetect((_c = time === null || time === void 0 ? void 0 : time.comment) !== null && _c !== void 0 ? _c : "")}</div>
                        </React.Fragment>);
        });
        return (<div key={(_b = location === null || location === void 0 ? void 0 : location.name) !== null && _b !== void 0 ? _b : "location"}>
                    <div>{urlDetect((_c = location === null || location === void 0 ? void 0 : location.name) !== null && _c !== void 0 ? _c : "")}, {location === null || location === void 0 ? void 0 : location.address}, {location === null || location === void 0 ? void 0 : location.zipcode} {location === null || location === void 0 ? void 0 : location.city}</div>
                    <div className={eventStyle.timesGrid}>
                        {times}
                    </div>
                </div>);
    });
    return <div className={eventStyle.locationsStack}>{locs}</div>;
};
export const Event = ({ data }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const event = (_a = data.warmerMaiEvent) === null || _a === void 0 ? void 0 : _a.event;
    const locations = ((_b = event === null || event === void 0 ? void 0 : event.locations) !== null && _b !== void 0 ? _b : []);
    // @ts-ignore
    const locationsTimes = <LocationsTimes locations={locations}/>;
    // @ts-ignore
    const ribbon = <Ribbon status={event === null || event === void 0 ? void 0 : event.status}/>;
    return (<Layout>
            <main id="main" role="main" className={eventStyle.eventStack}>
                <div className={eventStyle.eventTitle}>
                    {<h1>{event === null || event === void 0 ? void 0 : event.title}</h1>}
                    {(event === null || event === void 0 ? void 0 : event.subTitle) && <h2>{event === null || event === void 0 ? void 0 : event.subTitle}</h2>}
                </div>
                <div className={eventStyle.eventMainContent}>
                    <div>
                        <div className={eventStyle.eventContent}>
                            <div>
                                <div className={eventStyle.eventTextStack}>
                                    <div>
                                        <div className={eventStyle.eventTextPanel}>
                                            {ribbon}
                                            {formatText((_c = event === null || event === void 0 ? void 0 : event.description) !== null && _c !== void 0 ? _c : "")}
                                        </div>
                                        <div className={`${eventStyle.eventTextPanel} ${eventStyle.infoPanel}`}>
                                            <EventSection label="Preis">{event === null || event === void 0 ? void 0 : event.cost}</EventSection>
                                            <EventSection label="Reservierung">{urlDetect((_d = event === null || event === void 0 ? void 0 : event.reservation) !== null && _d !== void 0 ? _d : "")}</EventSection>
                                            <EventSection label="Ort & Zeit">
                                                {locationsTimes}
                                            </EventSection>
                                            {(event === null || event === void 0 ? void 0 : event.homepage) &&
        <EventSection label="Homepage"><a href={normalizeLink((_e = event === null || event === void 0 ? void 0 : event.homepage) !== null && _e !== void 0 ? _e : "")} target="_blank">{event === null || event === void 0 ? void 0 : event.homepage}</a></EventSection>}
                                        </div>
                                    </div>
                                </div>
                                <div className={eventStyle.eventImageStack}>
                                    <div>
                                        <img alt={(_f = event === null || event === void 0 ? void 0 : event.title) !== null && _f !== void 0 ? _f : "image"} src={`${imageUrlPrefix}${event === null || event === void 0 ? void 0 : event.id}/big/${(_g = event === null || event === void 0 ? void 0 : event.picture) === null || _g === void 0 ? void 0 : _g.id}`}/>
                                    </div>
                                    <div>
                                        {((_h = event === null || event === void 0 ? void 0 : event.logo) === null || _h === void 0 ? void 0 : _h.id) &&
        <img alt={(_j = event === null || event === void 0 ? void 0 : event.title) !== null && _j !== void 0 ? _j : "image"} src={`${imageUrlPrefix}${event === null || event === void 0 ? void 0 : event.id}/big/${(_k = event === null || event === void 0 ? void 0 : event.logo) === null || _k === void 0 ? void 0 : _k.id}`}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Kind kind={(_l = event === null || event === void 0 ? void 0 : event.kind) !== null && _l !== void 0 ? _l : ""} height="8ch"/>
                    </div>
                </div>
            </main>
        </Layout>);
};
export const query = graphql `
    query SingleEvent ($slug: String!)  {
        warmerMaiEvent(fields: { slug: { eq: $slug } }) {
            event {
                id
                title
                subTitle
                cost
                description
                homepage
                kind
                status
                locations {
                    address
                    city
                    name
                    zipcode
                    times {
                        day
                        time
                        comment
                    }
                }
                logo {
                    id
                }
                picture {
                    id
                }
                reservation
            }
        }
    }
`;
export default Event;
